/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let GPathSchoolDashboard = {
  /**
   * gpath_list_school_users
   */
  async gpath_list_school_users (context, whereFilter) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      for (let key in whereFilter) {
        post_data.append(key, whereFilter[key]);
      }

      return await request.curl(context, "gpath_list_school_users", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_list_school_users() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false;
    }
  }
}

export {
  GPathSchoolDashboard
}
